<template>
  <div id="settings_pagecontainer_master" class="canna_account_pageZone"
    :class="(routeName==='settings_main_account' || routeName==='settings_main_password')?'canna_pageFlexZone' : ''"
    :style="(routeName==='settings_main_account')?'overflow: hidden;' : ''"
  >
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'settings_mainContainer',
  data () { 
    return {
      version: '1.0.1',
      debugEls: false 
    }
  },
  computed: {
    routeName () {
      return this.$route.name
    }
    // ,
    // route_noHeader () {
    //   var currx = this.$route.name
    //   //var nohd = ['plandetalleindex_joblibrary', 'plandetalleindex_jobdetail', 'plandetalleindex_jobsetup']
    //   return (nohd.indexOf(currx) >= 0) ? true : false 
    // }
  },
  watch: {

  },
  methods: {

  },
  // component especific
  beforeCreate () {
    this.$cannaDebug('-- settingsLayout -- mainContainer -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- settingsLayout -- mainContainer -- created')
  },
  beforeMount () {
    // this.$cannaDebug('-- settingsLayout -- mainContainer -- beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- settingsLayout -- mainContainer -- mounted')
  },
  beforeUpdate () {
    // this.$cannaDebug('-- settingsLayout -- mainContainer -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- settingsLayout -- mainContainer -- updated')
  },
  activated () {
    this.$cannaDebug('-- settingsLayout -- mainContainer -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- settingsLayout -- mainContainer -- deactivated')
  },
  beforeDestroy () {
    this.$cannaDebug('-- settingsLayout -- mainContainer -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- settingsLayout -- mainContainer -- destroyed')
  }
}
</script>